import React, { memo, useContext } from "react";
import { Breadcrumb, Layout } from "antd";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { PageTitleContext } from "PageTitleContext";
import CustomRow from "components/UiComponents/CustomRow/index.jsx";
import CustomColumn from "components/UiComponents/CustomColumn/index.jsx";
import CustomHeading from "components/UiComponents/CustomHeading";
import { BREADCRUMB_SEPARATOR } from "utils/Constants/Constant";
import { unAuthenticatedRoutes } from "utils/Constants/routes.constant";
import "./Header.less";
import { Link } from "react-router-dom";

const TopHeader = () => {
  const { Header } = Layout;
  const breadcrumbs = useBreadcrumbs();
  const { pageTitle } = useContext(PageTitleContext);

  // Filter the breadcrumbs to exclude the home route and numeric segments (IDs)
  const filteredBreadcrumbs = breadcrumbs.filter(({ match }) => {
    const segments = match.pathname.split("/");
    return (
      match.pathname !== unAuthenticatedRoutes.AUTHSCREEN &&
      !segments.some((segment) => /^\d+$/.test(segment))
    );
  });

  // return (
  //   <Header className="headerWrapper">
  //     <CustomRow>
  //       <CustomColumn xs={24} sm={24} md={24} lg={24} xl={24}>
  //         <CustomHeading className="title">{pageTitle}</CustomHeading>

  //         {/* Render Breadcrumb only if there are more than one breadcrumb items */}
  //         {filteredBreadcrumbs.length > 1 && (
  //           <Breadcrumb separator={BREADCRUMB_SEPARATOR}>
  //             {filteredBreadcrumbs.map(({ match, breadcrumb }) => (
  //               <Breadcrumb.Item key={match.pathname}>
  //                 {breadcrumb}
  //               </Breadcrumb.Item>
  //             ))}
  //           </Breadcrumb>
  //         )}
  //       </CustomColumn>
  //     </CustomRow>
  //   </Header>
  // );

  return (
    <Header className="headerWrapper">
      <CustomRow>
        <CustomColumn xs={24} sm={24} md={24} lg={24} xl={24}>
          <CustomHeading className="title">{pageTitle}</CustomHeading>

          {/* Render Breadcrumb only if there are more than one breadcrumb items */}
          {filteredBreadcrumbs.length > 1 && (
            <Breadcrumb separator={BREADCRUMB_SEPARATOR}>
              {filteredBreadcrumbs.map(({ match, breadcrumb }, index) => {
                const isLastBreadcrumb =
                  index === filteredBreadcrumbs.length - 1;
                return (
                  <Breadcrumb.Item key={match.pathname}>
                    {isLastBreadcrumb ? (
                      breadcrumb
                    ) : (
                      <Link to={match.pathname}>{breadcrumb}</Link>
                    )}
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
          )}
        </CustomColumn>
      </CustomRow>
    </Header>
  );
};

export default memo(TopHeader);

import { Layout } from "antd";
import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import ScrollProgressBar from "components/CustomComponents/ScrollProgress";
import TopHeader from "./TopHeader";

const { Content } = Layout;
function MainLayout() {
  return (
    <div className="layout-sec">
      <Layout>
        <Sidebar />
        <Layout>
          <TopHeader />
          <Content className="main-content-wrapper" id="main-content-wrapper">
            <ScrollProgressBar />
            <Outlet />
            {/* <CustomFooter>
              <p>©{new Date().getFullYear()} Koderlabs. All rights reserved.</p>
            </CustomFooter> */}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default MainLayout;

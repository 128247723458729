import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: JSON.parse(localStorage.getItem("cart")) || [],
  total: JSON.parse(localStorage.getItem("total")) || 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart: (state, { payload }) => {
      const existingItem = state.cart.find((item) => item.id === payload.id);
      if (existingItem) {
        // existingItem.quantity += 1;
        // If payload.productQuantity exists, update the quantity directly, don't increment
        if (payload.productQuantity) {
          existingItem.quantity = payload.quantity;
        } else {
          // Otherwise, increment the quantity by 1
          existingItem.quantity += 1;
        }
      } else {
        state.cart.push({
          ...payload,
          // Check if its a draft order (payload has productQuantitiy)
          quantity: payload.productQuantity ? payload.quantity : 1,
          availableQuantitiy: payload.productQuantity
            ? payload.productQuantity
            : payload.quantity,
        });
      }

      if (!existingItem) {
        state.total += payload.productQuantity
          ? payload.price * payload.quantity
          : payload.price;
      }
      localStorage.setItem("cart", JSON.stringify(state.cart));
      localStorage.setItem("total", JSON.stringify(state.total));
    },
    removeItemFromCart: (state, { payload }) => {
      const item = state.cart.find((item) => item.id === payload.id);
      if (item) {
        state.total -= item.price * item.quantity;
        state.cart = state.cart.filter((item) => item.id !== payload.id);
        localStorage.setItem("cart", JSON.stringify(state.cart));
        localStorage.setItem("total", JSON.stringify(state.total));
      }
    },
    updateCartItemQuantity: (state, { payload }) => {
      const { id, quantity } = payload;
      const item = state.cart.find((item) => item.id === id);
      if (item) {
        state.total -= item.price * item.quantity;
        item.quantity = Math.max(1, quantity);
        state.total += item.price * item.quantity;
        localStorage.setItem("cart", JSON.stringify(state.cart));
        localStorage.setItem("total", JSON.stringify(state.total));
      }
    },
    clearCart: (state) => {
      state.cart = [];
      state.total = 0;
      localStorage.setItem("cart", JSON.stringify(state.cart));
      localStorage.setItem("total", JSON.stringify(state.total));
    },
  },
});

export const {
  addItemToCart,
  removeItemFromCart,
  updateCartItemQuantity,
  clearCart,
} = cartSlice.actions;

export default cartSlice;

import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/index.routes";
import { PageTitleContext } from "./PageTitleContext";
import { InfinitySpin } from "react-loader-spinner";
import "locales/config";
import "./App.less";
import "./App.css";

function App() {
  const [pageTitle, setPageTitle] = useState("");
  const [breadcrumbItems] = useState([]);

  const [backBtn, setBackBtn] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    function updateOnlineStatus() {
      setIsOnline(navigator.onLine);
    }

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return (
    <>
      <PageTitleContext.Provider
        value={{
          pageTitle,
          setPageTitle,
          breadcrumbItems,
          backBtn,
          setBackBtn,
        }}
      >
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </PageTitleContext.Provider>

      {!isOnline && (
        <div className="noConnectionLoader loaderContainer">
          <InfinitySpin width="200" color="#013744" />
          <h2 className="noConnectionError">No Internet Connection</h2>
        </div>
      )}
    </>
  );
}

export default App;

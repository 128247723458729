import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomButton from "components/UiComponents/CustomButton";
import CustomResult from "components/UiComponents/CustomResult";
import { USER_TYPE } from "utils/Constants/Constant";
import { authenticatedRoutes } from "utils/Constants/routes.constant";
import { useAuth } from "hooks/useAuth";
import "./UnAuthorized.less";

const UnAuthorized = () => {
  const navigate = useNavigate();
  const { userObj } = useAuth();
  const { t } = useTranslation();

  return (
    <section className="unAuthorized">
      <CustomResult
        status="403"
        title="403"
        subTitle={t("sorryYouAreNotAuthorized")}
        extra={
          <CustomButton
            onClick={() => {
              if (userObj?.userRole === USER_TYPE?.ADMIN) {
                navigate(`/${authenticatedRoutes.DASHBOARD}`);
              } else {
                navigate(`/${authenticatedRoutes.PRODUCT_CATALOGS}`);
              }
            }}
          >
            {t("backHome")}
          </CustomButton>
        }
      />
    </section>
  );
};

export default UnAuthorized;

import ProductCatalogIcon from "assets/SvgFiles/ProductCatalogIcon";
import InvoicesIcon from "assets/SvgFiles/InvoicesIcon";
import BusinessDetailsIcon from "assets/SvgFiles/BusinessDetailsIcon";
import AccountIcon from "assets/SvgFiles/AccountIcon";
import BriefCaseIcon from "assets/SvgFiles/BriefCaseIcon";
import CustomerIcon from "assets/SvgFiles/CustomerIcon";
import ReportIcon from "assets/SvgFiles/ReportIcon";
import DashboardIcon from "assets/SvgFiles/DashboardIcon";

export const unAuthenticatedRoutes = {
  AUTHSCREEN: "/",
  CHECK_EMAIL: "check-email",
  FORGOT_PASSWORD: "forgot-password",
  LOGIN: "login",
  RESET_PASSWORD: "reset-password",
  SIGNUP_COMPLETE: "signup-complete",
  UNAUTHORIZED: "unauthorized",
};

export const authenticatedRoutes = {
  ACCOUNT: "account",
  ADD: "add",
  EDIT: "edit",
  DETAIL: "detail",
  BUSINESS_DETAILS: "business-details",
  CHANGE_PASSWORD: "change-password",
  CHECKOUT: "checkout",
  CUSTOMERS: "customers",
  DASHBOARD: "dashboard",
  INVOICES: "invoices",
  PRODUCT_CATALOGS: "product-catalogs",
  PRODUCTS: "products",
  PROJECTS: "projects",
  REPORTS: "reports",
  SERVICES: "services",
  USERS: "users",
  ORDERS: "orders",
};

export const permissionsToRoutes = {
  // [authenticatedRoutes.DASHBOARD]: [], // This route does not require any permision
  [authenticatedRoutes.DASHBOARD]: ["view_dashboard", "list_dashboard"],
  [authenticatedRoutes.CUSTOMERS]: ["view_customers", "list_customers"],
  [authenticatedRoutes.PRODUCTS]: ["view_products", "list_products"],
  [authenticatedRoutes.ORDERS]: [
    "view_orders",
    "list_orders",
    "update_orders",
    "delete_orders",
  ],
  [authenticatedRoutes.INVOICES]: [
    "view_invoices",
    "list_invoices",
    "update_invoices",
    "delete_invoices",
  ],
  [authenticatedRoutes.REPORTS]: ["view_reports", "list_reports"],

  // CUSTOMER
  [authenticatedRoutes.PRODUCT_CATALOGS]: [
    "view_product_catalog",
    "list_product_catalog",
  ],
  [authenticatedRoutes.BUSINESS_DETAILS]: [
    "view_business_details",
    "list_business_details",
  ],

  [authenticatedRoutes.ACCOUNT]: [
    "view_account",
    "list_account",
    "update_account",
    "delete_account",
  ],
};

export const AllMenusItems = [
  {
    label: "Dashboard",
    linkTo: authenticatedRoutes.DASHBOARD,
    selectedOptionKey: "dashboard",
    icon: <DashboardIcon />,
  },
  {
    label: "Customers",
    linkTo: authenticatedRoutes.CUSTOMERS,
    selectedOptionKey: "customers",
    icon: <CustomerIcon />,
  },
  {
    label: "Products",
    linkTo: authenticatedRoutes.PRODUCTS,
    selectedOptionKey: "products",
    icon: <ProductCatalogIcon />,
  },

  {
    label: "Product Catalog",
    linkTo: authenticatedRoutes.PRODUCT_CATALOGS,
    selectedOptionKey: "productCatalogs",
    icon: <ProductCatalogIcon />,
  },
  {
    label: "Orders",
    linkTo: authenticatedRoutes.ORDERS,
    selectedOptionKey: "orders",
    icon: <BriefCaseIcon />,
  },
  {
    label: "Invoices",
    linkTo: authenticatedRoutes.INVOICES,
    selectedOptionKey: "invoices",
    icon: <InvoicesIcon />,
  },
  {
    label: "Reports",
    linkTo: authenticatedRoutes.REPORTS,
    selectedOptionKey: "reports",
    icon: <ReportIcon />,
  },
  {
    label: "Business Details",
    linkTo: authenticatedRoutes.BUSINESS_DETAILS,
    selectedOptionKey: "businessDetails",
    icon: <BusinessDetailsIcon />,
  },
  {
    label: "Account",
    linkTo: authenticatedRoutes.ACCOUNT,
    selectedOptionKey: "account",
    icon: <AccountIcon />,
  },
];
